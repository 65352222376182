/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap-v5'
import AccountsService from '../../../../app/modules/assets/redux/TeachersCRUD'
import {TextEditor} from '../../../../app/components/TextEditor'

const accountSchema = Yup.object().shape({
  name: Yup.string().required('Tên tài khoản không được để trống'),
  password: Yup.string()
    .min(7, 'Mật khẩu ngắn nhất là 7 kí tự')
    .max(50, 'Mật khẩu dài nhất là 50 kí tự')
    .required('Mật khẩu tài khoản không được để trống'),
  email: Yup.string()
    .email('Địa chỉ email chưa đúng đinh dạng')
    .min(7, 'Địa chỉ email ngắn nhất là 7 kí tự')
    .max(50, 'Địa chỉ email dài nhất là 50 kí tự')
    .required('Địa chỉ email tài khoản không được để trống'),
  phone_number: Yup.string().required('Số điện thoại không được để trống'),
  status: Yup.number().required('Trạng thái khách hàng cần phải chọn'),
  roles: Yup.number().min(1, 'Cần phải nhập quyền tài khoản').required('Số điện thoại không được để trống'),
})

const accountSchemaEdit = Yup.object().shape({
  name: Yup.string().required('Tên tài khoản không được để trống'),
  email: Yup.string()
    .email('Địa chỉ email chưa đúng đinh dạng')
    .min(7, 'Địa chỉ email ngắn nhất là 7 kí tự')
    .max(50, 'Địa chỉ email dài nhất là 50 kí tự')
    .required('Địa chỉ email tài khoản không được để trống'),
  phone_number: Yup.string().required('Số điện thoại không được để trống'),
  status: Yup.number().required('Trạng thái khách hàng cần phải chọn'),
})

export type Props = {
  setIsReload: any;
  accounts: any;
  setAccounts: any;
  showCreateModal: boolean;
  handleCloseCreateModal: any;
  initialValues: any;
  roles: any;
  notify: any;
}

export default function TeachersModal({
  setIsReload,
  accounts,
  setAccounts,
  showCreateModal,
  handleCloseCreateModal,
  initialValues,
  roles,
  notify,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [label, setLabel] = useState("Tạo mới tài khoản");
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: initialValues?.id?accountSchemaEdit:accountSchema,
    onSubmit: (values, {resetForm, setStatus, setSubmitting}) => {
      resetForm();
      setLoading(true)
      setTimeout(() => {
        if(initialValues?.id && initialValues.id){
          // update contact
          AccountsService.updateContactById(values)
            .then((response: any) => {
              const newState = accounts.map((obj: any) => {
                if (obj.id === values.id) {
                  return {...obj, 
                            name: values.name, 
                            email: values.email, 
                            phone_number: values.phone_number, 
                            status: values.status, 
                            roles: values.roles, 
                            banking_name:values.banking_name, 
                            banking_number: values.banking_number,
                            about: values.about
                          };
                }
                return obj;
              });
              setAccounts(newState);

              // close modal
              handleCloseCreateModal();

              // set loading
              setLoading(false);

              // reset form
              resetForm();

              // notify
              notify("Bạn đã cập nhật giáo viên thành công", "show");
            })
            .catch(() => {
              setLoading(false)
              setSubmitting(false)
              setStatus('Có lỗi của hệ thống, mời bạn thử lại')
            })
        } else {
          // create new name
          AccountsService.createAccount(values)
            .then((response: any) => {
              setAccounts([response.data.data, ...accounts])

              // close modal
              handleCloseCreateModal();

              // set loading
              setLoading(false);

              // reset form
              resetForm();

              // notify
              notify("Bạn đã khởi tạo giáo viên thành công", "show");
            })
            .catch(() => {
              setLoading(false)
              setSubmitting(false)
              setStatus('Tài khoản đã tồn tại, mời bạn nhập thông tin tài khoản khác.')
            })
        }
      }, 1000)
    }
  })

  useEffect(() => {
    if(initialValues?.id){
      setLabel("Cập nhật thông tin tài khoản");

      // set is edit
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [initialValues])

  return (
    <Modal
      // className='bg-white'
      id='kt_modal_accounts'
      aria-hidden='true'
      dialogClassName='modal-fullscreen'
      show={showCreateModal}
      onHide={handleCloseCreateModal}
      animation={true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleCloseCreateModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </div>
      </div>
      <div className='modal-body scroll-y'>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Tài khoản hệ thống</h1>
        </div>
        
        {/* begin form */}
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ""
          )}
          <input type='hidden' name='id' value={initialValues.id} />
            {/* begin::Form group */}
            <div className='row'>
              <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Tên tài khoản</label>
                <input
                  placeholder='Tên tài khoản'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='text'
                  name='name'
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Số điện thoại</label>
                <input
                  placeholder='Số điện thoại'
                  {...formik.getFieldProps('phone_number')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.phone_number && formik.errors.phone_number},
                    {
                      'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                    }
                  )}
                  type='text'
                  name='phone_number'
                  autoComplete='off'
                />
                {formik.touched.phone_number && formik.errors.phone_number && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.phone_number}</span>
                  </div>
                )}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='row'>
              <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Địa chỉ email</label>
                <input
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Trạng thái tài khoản</label>
                <select
                  data-kt-select2='true'
                  data-placeholder='Trạng thái'
                  data-allow-clear='true'
                  defaultValue={'1'}
                  {...formik.getFieldProps('status')}
                  className={clsx(
                    'form-select form-select-solid',
                    {'is-invalid': formik.touched.status && formik.errors.status},
                    {
                      'is-valid': formik.touched.status && !formik.errors.status,
                    }
                  )}
                >
                  <option value='1'>Đang hoạt động</option>
                  <option value='4'>Đang bị khoá</option>
                </select>
                {formik.touched.status && formik.errors.status && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.status}</span>
                  </div>
                )}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            {isEdit? (
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                      {/* begin::Label */}
                      <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Phân quyền tài khoản</label>
                      {/* end::Label */}
                    </div>
                  </div>
                  <select
                    data-kt-select2='true'
                    data-placeholder='Phân quyền tài khoản'
                    data-allow-clear='true'
                    defaultValue={'1'}
                    {...formik.getFieldProps('roles')}
                    className={clsx(
                      'form-select form-select-solid',
                      {'is-invalid': formik.touched.roles && formik.errors.roles},
                      {
                        'is-valid': formik.touched.roles && !formik.errors.roles,
                      }
                    )}
                  >
                    <option value='0'>Chọn quyền tài khoản</option>
                    {roles.map((role: any) => {
                      if(role.guard_name === "teacher"){
                        return (
                          <option value={role.id}>{role.name}</option>    
                        )
                      }
                    })}
                  </select>
                  {formik.touched.roles && formik.errors.roles && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.roles}</span>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                      {/* begin::Label */}
                      <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Mật khẩu</label>
                      {/* end::Label */}
                    </div>
                  </div>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                      {/* begin::Label */}
                      <label className='form-label fw-bolder text-dark fs-6 mb-0 required'>Phân quyền tài khoản</label>
                      {/* end::Label */}
                    </div>
                  </div>
                  <select
                    data-kt-select2='true'
                    data-placeholder='Phân quyền tài khoản'
                    data-allow-clear='true'
                    defaultValue={'1'}
                    {...formik.getFieldProps('roles')}
                    className={clsx(
                      'form-select form-select-solid',
                      {'is-invalid': formik.touched.roles && formik.errors.roles},
                      {
                        'is-valid': formik.touched.roles && !formik.errors.roles,
                      }
                    )}
                  >
                    <option value='0'>Chọn quyền tài khoản</option>
                    {roles.map((role: any) => {
                      if(role.guard_name === "teacher"){
                        return (
                          <option value={role.id}>{role.name}</option>    
                        )
                      }
                    })}
                  </select>
                  {formik.touched.roles && formik.errors.roles && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.roles}</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='row'>
              <div className='col-lg-4 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Giới thiệu giáo viên</label>
                <textarea
                  className={clsx(
                    'form-control form-control-lg form-control-solid min-h-200px',
                    {
                      'is-invalid': formik.touched.about && formik.errors.about,
                    },
                    {
                      'is-valid': formik.touched.about && !formik.errors.about,
                    }
                  )}
                  rows={1}
                  placeholder='Thông tin giới thiệu...'
                  {...formik.getFieldProps('about')}
                ></textarea>
              </div>
              
              <div className='col-lg-4 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Thông tin bằng cấp</label>
                <textarea
                  className={clsx(
                    'form-control form-control-lg form-control-solid min-h-200px',
                    {
                      'is-invalid': formik.touched.certificate && formik.errors.certificate,
                    },
                    {
                      'is-valid': formik.touched.certificate && !formik.errors.certificate,
                    }
                  )}
                  rows={1}
                  placeholder='Thông tin bằng cấp...'
                  {...formik.getFieldProps('certificate')}
                ></textarea>
              </div>

              <div className='col-lg-4 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Thông tin thêm giáo viên</label>
                <textarea
                  className={clsx(
                    'form-control form-control-lg form-control-solid min-h-200px',
                    {
                      'is-invalid': formik.touched.information && formik.errors.information,
                    },
                    {
                      'is-valid': formik.touched.information && !formik.errors.information,
                    }
                  )}
                  rows={1}
                  placeholder='Thông tin thêm...'
                  {...formik.getFieldProps('information')}
                ></textarea>
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='row'>
              <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Tài khoản ngân hàng</label>
                <input
                  placeholder='Tài khoản ngân hàng'
                  {...formik.getFieldProps('banking_name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.banking_name && formik.errors.banking_name},
                    {
                      'is-valid': formik.touched.banking_name && !formik.errors.banking_name,
                    }
                  )}
                  type='text'
                  name='banking_name'
                  autoComplete='off'
                />
                {formik.touched.banking_name && formik.errors.banking_name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.banking_name}</span>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row fv-plugins-icon-container mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Số tài khoản ngân hàng</label>
                <input
                  placeholder='Số tài khoản ngân hàng'
                  {...formik.getFieldProps('banking_number')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.banking_number && formik.errors.banking_number},
                    {
                      'is-valid': formik.touched.banking_number && !formik.errors.banking_number,
                    }
                  )}
                  type='text'
                  name='banking_number'
                  autoComplete='off'
                />
                {formik.touched.banking_number && formik.errors.banking_number && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.banking_number}</span>
                  </div>
                )}
              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>{label}</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Vui lòng chờ trong giây lát...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          {/* end::Form group */}
        </form>
        {/* end form */}
      </div>
    </Modal>
  )
}
